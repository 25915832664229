export default class LibraryObjectAccessInfos {
    canBeAccessed = false;
    contentUrl = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(json) {
        const infos = new LibraryObjectAccessInfos();
        infos.canBeAccessed = json['CanBeAccessed'];
        infos.contentUrl = json['ContentUrl'];
        return infos;
    }
}

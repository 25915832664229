import { Api } from '@campus/auth';
import Response from '../models/Response';
import LibraryObjectAccessInfos from '../models/LibraryObjectAccessInfos';
export async function getLibraryObjectAccessInfos(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = '/libraryobject/can-be-accessed';
        const response = await axiosInstance.get(endpoint, {
            ...(params.abortSignal ? { signal: params.abortSignal } : {}),
            params: {
                chapterId: params.isAChapter ? params.objectId : null,
                libraryObjectId: params.isAChapter ? null : params.objectId
            }
        });
        const infos = LibraryObjectAccessInfos.fromJson(response.data);
        return Response.fromData(infos, response.status);
    }
    catch (error) {
        return Response.error(error);
    }
}
